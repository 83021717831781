
import {
  deleteMessageReservation,
  updateMessageReservation,
  updateMultipleMessageReservation
} from "@/api/message_reservation";
import MBaseModal from "@/components/MBaseModal.vue";
import MButton from "@/components/MButton.vue";
import MIcon from "@/components/MIcon.vue";
import MSelectBox from "@/components/form/MSelectBox.vue";
import MTextArea from "@/components/form/MTextArea.vue";
import MTextField from "@/components/form/MTextField.vue";
import { Classroom } from "@/entities/classroom";
import { Student } from "@/entities/student";
import { School } from "@/entities/school";
import {
  MessageReservation,
  MessageTo,
  MessageToType
} from "@/entities/message_reservation";
import { Group } from "@/entities/group";
import store from "@/store";
import dayjs from "dayjs";
import { Options, Vue } from "vue-class-component";
import { MessageUser, MessageContent } from "@/entities/message";
import { Getter } from "@/store/helper";
import { saveErrorLog } from "@/api/error";

function convertToMessageTo(
  type: MessageToType,
  groups: Group[],
  schools: School[],
  classrooms: Classroom[],
  students: Student[],
  selectedGroupId: string,
  selectSchoolId: string,
  selectClassroomId: string
): MessageTo {
  const messageTo: MessageTo = {
    type
  };
  if (type === "group") {
    const matchGroup = groups.filter(group => group.ref.id === selectedGroupId);
    if (matchGroup.length > 0) {
      messageTo.group = matchGroup[0].ref;
    } else {
      messageTo.type = "all";
    }
  } else if (type === "school") {
    const matchSchool = schools.filter(
      school => school.ref.id === selectSchoolId
    );
    if (matchSchool.length > 0) {
      messageTo.school = matchSchool[0].ref;
    } else {
      messageTo.type = "all";
    }
  } else if (type === "classroom") {
    const matchClassroom = classrooms.filter(
      classroom => classroom.ref.id === selectClassroomId
    );
    if (matchClassroom.length > 0) {
      messageTo.classroom = matchClassroom[0].ref;
    } else {
      messageTo.type = "all";
    }
  } else if (type === "students") {
    messageTo.students = students.map(student => student.ref);
  }

  return messageTo;
}

@Options({
  components: {
    MBaseModal,
    MButton,
    MIcon,
    MSelectBox,
    MTextArea,
    MTextField
  },
  emits: ["close"],
  props: {
    messageReservation: Object,
    schools: Array,
    classrooms: Array,
    students: Array,
    groups: Array
  }
})
export default class MsEditMessageReservationModal extends Vue {
  messageReservation!: MessageReservation;
  selectSchoolId = "";
  schools: School[] = [];
  selectClassroomId = "";
  classrooms: Classroom[] = [];
  students: Student[] = [];
  selectedGroupId = "";
  groups: Group[] = [];
  toType: MessageToType = "group";
  selectedStudentIds: string[] = [];
  message = "";
  reserveDay = "";
  reserveTime = "18:00";

  @Getter("isAdmin") isAdmin!: boolean;

  get types(): { type: MessageToType; text: string }[] {
    const ret: { type: MessageToType; text: string }[] = [
      {
        type: "group",
        text: "グループ"
      }
    ];
    if (this.isAdmin) {
      ret.push({
        type: "school",
        text: "スクール"
      });
    } else {
      ret.push({
        type: "classroom",
        text: "教室"
      });
    }
    return ret;
  }

  get selectedStudents(): Student[] {
    return this.students.filter(student =>
      this.selectedStudentIds.includes(student.ref.id)
    );
  }

  get selectClassroom(): Classroom | null {
    const matchClassrooms = this.classrooms.filter(
      item => item.ref.id === this.selectClassroomId
    );
    if (matchClassrooms.length === 0) {
      return null;
    }
    return matchClassrooms[0];
  }

  get filteredStudents(): Student[] {
    const baseStudents = this.students.filter(
      student => !student.data.recessTime || student.data.recessTime <= 0
    );
    if (this.toType === "group") {
      const matchGroups = this.groups.filter(
        group => group.ref.id === this.selectedGroupId
      );
      if (matchGroups.length > 0) {
        const groupStudentIds = matchGroups[0].data.result.map(item => item.id);
        return baseStudents.filter(student =>
          groupStudentIds.includes(student.ref.id)
        );
      } else {
        return baseStudents;
      }
    } else if (this.toType === "school") {
      if (this.selectSchoolId) {
        return baseStudents.filter(
          item =>
            item.ref.parent.parent!.parent.parent!.id === this.selectSchoolId
        );
      } else {
        return baseStudents;
      }
    } else if (this.toType === "classroom") {
      if (this.selectClassroomId) {
        return baseStudents.filter(
          item => item.ref.parent.parent!.id === this.selectClassroomId
        );
      } else {
        return baseStudents;
      }
    } else if (this.toType === "students") {
      return this.selectedStudents;
    } else {
      return baseStudents;
    }
  }

  get validMessage() {
    return (
      this.messageReservation.data.messageType !== "text" ||
      this.message.length > 0
    );
  }

  get validReserveDay(): boolean {
    return /^\d{4}-\d{2}-\d{2}$/.test(this.reserveDay);
  }

  get validReserveTime(): boolean {
    return /^\d{2}:\d{2}$/.test(this.reserveTime);
  }

  get validReserve() {
    return this.validReserveDay && this.validReserveTime;
  }

  get validData() {
    return (
      this.validMessage && this.filteredStudents.length > 0 && this.validReserve
    );
  }

  get toOnlyRoomUsers(): boolean {
    return this.isAdmin;
  }

  removeStudent(id: string) {
    this.selectedStudentIds = this.selectedStudentIds.filter(
      item => item !== id
    );
  }

  async updateMessageReservation() {
    if (!this.validData || !store.state.role) {
      return;
    }

    let fromName: string;
    if (store.state.role.data.type === "tutor" && store.state.tutor) {
      fromName = store.state.tutor.main.data.name;
    } else if (store.state.role.data.type === "admin" && store.state.admin) {
      fromName = store.state.admin.name;
    } else {
      return;
    }
    const messageUser: MessageUser = {
      type: store.state.role.data.type,
      userId: store.state.role.ref.id,
      name: fromName
    };

    const messageTo = convertToMessageTo(
      this.toType,
      this.groups,
      this.schools,
      this.classrooms,
      this.selectedStudents,
      this.selectedGroupId,
      this.selectSchoolId,
      this.selectClassroomId
    );

    const reservationDateData = dayjs(
      `${this.reserveDay} ${this.reserveTime}`
    ).locale("ja");
    const reservationTime = reservationDateData.unix();

    store.commit("START_LOADING", "更新中...");

    try {
      const schoolName = store.state.school
        ? store.state.school.data.name
        : undefined;
      if (this.messageReservation.data.messageType === "multiple") {
        await updateMultipleMessageReservation(
          this.messageReservation.ref,
          messageUser,
          messageTo,
          this.messageReservation.data.messageType,
          this.messageReservation.data.messageContents!.map(
            (c: MessageContent) => {
              if (c.type === "text") {
                return { type: "text", text: this.message };
              } else {
                return c;
              }
            }
          ),
          reservationTime,
          schoolName,
          this.toOnlyRoomUsers
        );
      } else {
        await updateMessageReservation(
          this.messageReservation.ref,
          messageUser,
          messageTo,
          this.messageReservation.data.messageType,
          this.messageReservation.data.messageType === "text"
            ? this.message
            : this.messageReservation.data.messageText!,
          reservationTime,
          schoolName,
          this.toOnlyRoomUsers
        );
      }
    } catch (e) {
      store.commit("END_LOADING");
      alert(e.message);
      await saveErrorLog(
        store.state.role,
        e.code,
        e.message,
        "Failed to update message reservation"
      );
    }

    store.commit("END_LOADING");

    this.$router.go(0);
  }

  async deleteMessageReservation() {
    store.commit("START_LOADING", "削除中...");
    try {
      await deleteMessageReservation(this.messageReservation.ref);
    } catch (e) {
      store.commit("END_LOADING");
      alert(e.message);
      await saveErrorLog(
        store.state.role,
        e.code,
        e.message,
        "Failed to delete message reservation"
      );
    }
    store.commit("END_LOADING");

    this.$router.go(0);
  }

  created() {
    if (this.messageReservation.data.to.type === "group") {
      this.toType = "group";
      this.selectedGroupId = this.messageReservation.data.to.group
        ? this.messageReservation.data.to.group.id
        : "";
    } else if (this.messageReservation.data.to.type === "school") {
      this.toType = "school";
      this.selectSchoolId = this.messageReservation.data.to.school
        ? this.messageReservation.data.to.school.id
        : "";
    } else if (this.messageReservation.data.to.type === "classroom") {
      this.toType = "classroom";
      this.selectClassroomId = this.messageReservation.data.to.classroom
        ? this.messageReservation.data.to.classroom.id
        : "";
    } else if (this.messageReservation.data.to.type === "students") {
      this.toType = "students";
      this.selectedStudentIds = this.messageReservation.data.to.students
        ? this.messageReservation.data.to.students.map(student => student.id)
        : [];
    }
    const timeTexts = dayjs(this.messageReservation.data.reservationTime * 1000)
      .locale("ja")
      .format("YYYY-MM-DD HH:mm")
      .split(" ");
    this.reserveDay = timeTexts[0];
    this.reserveTime = timeTexts[1];

    if (this.messageReservation.data.messageType === "text") {
      let messageArray = this.messageReservation.data.messageText!.split(
        "みんがく管理人:\n"
      );
      messageArray = messageArray[messageArray.length - 1].split("先生:\n");
      this.message = messageArray[messageArray.length - 1];
    }
    if (this.messageReservation.data.messageType === "multiple") {
      let messageArray = this.messageReservation.data
        .messageContents!.filter(c => c.type === "text")[0]
        .text.split("みんがく管理人:\n");
      messageArray = messageArray[messageArray.length - 1].split("先生:\n");
      this.message = messageArray[messageArray.length - 1];
    }
  }

  close() {
    this.$emit("close");
  }
}
