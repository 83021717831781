
import MButton from "@/components/MButton.vue";
import MIcon from "@/components/MIcon.vue";
import MLoadingIcon from "@/components/MLoadingIcon.vue";
import MTable from "@/components/MTable.vue";
import MNavBar from "@/components/MNavBar.vue";
import MHeaderPanel, { TabItem } from "@/components/MHeaderPanel.vue";
import MsSendModal from "@/components/student/MsSendModal.vue";
import MsEditMessageReservationModal from "@/components/student/MsEditMessageReservationModal.vue";
import MsEditMessageRegularlyReservationModal from "@/components/student/MsEditMessageRegularlyReservationModal.vue";
import { Student } from "@/entities/student";
import { Classroom } from "@/entities/classroom";
import { School } from "@/entities/school";
import { Group } from "@/entities/group";
import {
  MessageReservation,
  messageReservationCollectionKey
} from "@/entities/message_reservation";
import {
  MessageRegularlyReservation,
  messageRegularlyReservationCollectionKey
} from "@/entities/message_regularly_reservation";
import { Getter, State } from "@/store/helper";
import { Options, Vue } from "vue-class-component";
import store from "@/store";
import {
  convertToMessageRegularlyReservationTableData,
  convertToMessageReservationTableData,
  messageReservationsTableDefinition
} from "@/utils/table";

@Options({
  components: {
    MButton,
    MIcon,
    MLoadingIcon,
    MTable,
    MNavBar,
    MHeaderPanel,
    MsSendModal,
    MsEditMessageReservationModal,
    MsEditMessageRegularlyReservationModal
  }
})
export default class MessageList extends Vue {
  tabs: TabItem[] = [
    {
      text: "生徒一覧",
      link: "/student"
    },
    {
      text: "グループ管理",
      link: "/group"
    },
    {
      text: "メッセージ予約",
      link: "/message"
    }
  ];
  currentTabIndex = 2;
  searchKeyword = "";
  isDisplayedSendModal = false;
  isDisplayedEditReservationModal = false;
  isDisplayedEditRegularlyReservationModal = false;
  selectedReservationId = "";
  definitions = messageReservationsTableDefinition;

  @State("load", "messageReservation")
  load!: boolean;
  @State("messageReservations", "messageReservation")
  messageReservations!: MessageReservation[];
  @State("messageRegularlyReservations", "messageReservation")
  messageRegularlyReservations!: MessageRegularlyReservation[];

  @State("students") students!: Student[];
  @State("classrooms") classrooms!: Classroom[];
  @State("schools") schools!: School[];
  @State("groups") groups!: Group[];
  @Getter("isAdmin") isAdmin!: boolean;

  get selectedMessageReservation(): MessageReservation | null {
    const matchReservations = this.messageReservations.filter(
      item => item.ref.id === this.selectedReservationId
    );
    if (matchReservations.length > 0) {
      return matchReservations[0];
    } else {
      return null;
    }
  }

  get selectedMessageRegularlyReservation(): MessageRegularlyReservation | null {
    const matchReservations = this.messageRegularlyReservations.filter(
      item => item.ref.id === this.selectedReservationId
    );
    if (matchReservations.length > 0) {
      return matchReservations[0];
    } else {
      return null;
    }
  }

  get datas(): { [key: string]: string | number | boolean }[] {
    const ret: { [key: string]: string | number | boolean }[] = [];
    this.messageReservations.forEach(item => {
      return ret.push(
        convertToMessageReservationTableData(
          item,
          this.groups,
          this.schools,
          this.classrooms
        )
      );
    });
    this.messageRegularlyReservations.forEach(item =>
      ret.push(
        convertToMessageRegularlyReservationTableData(
          item,
          this.groups,
          this.schools,
          this.classrooms
        )
      )
    );
    return ret;
  }

  get filteredDatas(): { [key: string]: string | number | boolean }[] {
    if (this.searchKeyword.length === 0) {
      return this.datas;
    }
    return this.datas.filter(
      data =>
        Object.keys(data).includes("content") &&
        data.content
          .toString()
          .toLowerCase()
          .includes(this.searchKeyword.toLowerCase())
    );
  }

  showSendModal() {
    this.isDisplayedSendModal = true;
  }

  closeSendModal() {
    this.isDisplayedSendModal = false;
  }

  showEditModal(id: string) {
    const ids = id.split("/");
    if (ids.length !== 2) {
      return;
    }

    if (ids[0] === messageReservationCollectionKey) {
      this.selectedReservationId = ids[1];
      this.isDisplayedEditReservationModal = true;
    } else if (ids[0] === messageRegularlyReservationCollectionKey) {
      this.selectedReservationId = ids[1];
      this.isDisplayedEditRegularlyReservationModal = true;
    }
  }

  created() {
    store.dispatch("messageReservation/setup");
  }
}
